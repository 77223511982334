import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height:'auto',
  bgcolor: 'background.paper',
  border: '3px solid #113C54',
  boxShadow: 24,
  p: 4,
};

const AppointmentModal = ({ open, onClose, service }) => {
    console.log(service,"from model");
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        <IconButton
          onClick={onClose}
          style={{ position: 'absolute', top: 10, right: 10 }}
        >
          x
          </IconButton>
        <Typography id="modal-title" variant="h6" component="h2" sx={{ fontWeight:800 }}>
          Details
        </Typography>

        {service && ( <Box mt={2}> <Typography id="modal-description" sx={{ mt: 2 }}>
          <strong>ID: </strong>{service.id}
          <hr />
          <strong>Name: </strong>{service.name}
          <hr />
          <strong>Email: </strong>{service.email}
          <hr />
          <strong>Age: </strong>{service.age }
          <hr />
          <strong>Gender: </strong>{service.gender }
          <hr />
          <strong>Contact: </strong>{service.contact }
          <hr />
          <strong>City: </strong>{service.city}
          <hr />
          <strong>Date: </strong>{service.date}
          <hr />
          <strong>Time: </strong>{service.time}
          <hr />
          <strong>City: </strong>{service.city}
          <hr />
          <strong>Location </strong>{service.location}
          <hr />
          <strong>Department: </strong>{service.department }
          <hr />
          <strong>Doctor: </strong>{service.doctor }
          <hr />
          <strong>Problem: </strong>{service.problem }
          <hr />
          
         
          
          </Typography>
          </Box>)}
        
      </Box>
    </Modal>
  );
};

export default AppointmentModal;
