import React, { useEffect, Suspense, lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./Component/Home/header";
import Footer from "./Component/Home/footer";
import Booking from "./Pages/booking";
import AdminHeader from "./Pages/Admin/adminheader";
// import DashBoard from "./Pages/Admin/dashboard";
// import Staff from "./Pages/Admin/staff";
// import Blogs from "./Pages/Admin/blogs";
// import AddBlog from "./Pages/Admin/AddDetails/addblogs";
// import Appointments from "./Pages/Admin/appointments";
// import ConsultationQuery from "./Pages/Admin/consultationquery";
// import MyProfile from "./Pages/Admin/myprofile";
// import ChangePassword from "./Pages/Admin/changepassword";
// import AddStaff from "./Pages/Admin/AddDetails/addstaff";
// import AddLocation from "./Pages/Admin/AddDetails/addlocation";
// import AddDepartment from "./Pages/Admin/AddDetails/adddepartment";
// import AdminServices from "./Pages/Admin/adminservices";
// import AddService from "./Pages/Admin/AddDetails/addservices";
// import EditService from "./Pages/Admin/EditPages/editservices";
// import EditStaff from "./Pages/Admin/EditPages/editstaff";
// import EditBlog from "./Pages/Admin/EditPages/editblog";
// import EditLocation from "./Pages/Admin/EditPages/editlocation";
import AddAppointment from "./Pages/Admin/AddDetails/addappointments";
import EditAppointment from "./Pages/Admin/EditPages/editappointment";
// import ManageContent from "./Pages/Admin/managecontent";
// import ManageLocation from "./Pages/Admin/managelocations";
// import LogoChange from "./Pages/Admin/Configurations/logochange";
// import FaviconChange from "./Pages/Admin/Configurations/faviconchange";
// import SocialMediaProfiles from "./Pages/Admin/Configurations/socialmediaprofiles";
// import Timings from "./Pages/Admin/Configurations/timings";
// import SloganText from "./Pages/Admin/Configurations/slogantext";
// import Address from "./Pages/Admin/Configurations/address";
// import ManageContact from "./Pages/Admin/managecontact";
// import AdminLogin from "./Pages/Admin/adminlogin";
// import EditProfile from "./Pages/Admin/EditPages/editprofile";
// import Notification from "./Pages/Admin/notification";
import ManageSlots from "./Pages/Admin/manageslots";
import ProtectedRoute from "./Component/ProtectedRoute";
// import EditContent from "./Pages/Admin/EditPages/editcontent";
// import ManageDepartment from "./Pages/Admin/managedepartment";
// import EditDepartment from "./Pages/Admin/EditPages/editdepartment";
// import DoctorLogin from "./Pages/Doctor/doctorlogin";
// import { Dashboard } from "@mui/icons-material";
import LoaderH from "./Component/Loader/loader";
import Home from "./Pages/home"

// const Home = lazy(() => import("./Pages/home"));
const About = lazy(() => import("./Pages/about"));
const GetDetails = lazy(() => import("./Pages/getdetails"));
const ContactUs = lazy(() => import("./Pages/contactus"));
const Services = lazy(() => import("./Pages/services"));
const Blog = lazy(() => import("./Pages/blog"));
const BlogPage = lazy(() => import("./Component/Blog/blogpage"));
const PrivacyPolicy = lazy(() => import("./Pages/privacypolicy"));
const TermsofService = lazy(() => import("./Pages/termsofservice"));
const Error = lazy(() => import("./Component/Home/error"));
const AdminLogin = lazy(() => import("./Pages/Admin/adminlogin"));
const DoctorLogin = lazy(() => import("./Pages/Doctor/doctorlogin"));
const DashBoard = lazy(() => import("./Pages/Admin/dashboard"));
const Appointments = lazy(() => import("./Pages/Admin/appointments"));
const MyProfile = lazy(() => import('./Pages/Admin/myprofile'));
const EditProfile = lazy(() => import('./Pages/Admin/EditPages/editprofile'));
const ChangePassword = lazy(() => import('./Pages/Admin/changepassword'));
const Notification = lazy(() => import('./Pages/Admin/notification'));
const LogoChange = lazy(() => import('./Pages/Admin/Configurations/logochange'));
const FaviconChange = lazy(() => import('./Pages/Admin/Configurations/faviconchange'));
const SocialMediaProfiles = lazy(() => import('./Pages/Admin/Configurations/socialmediaprofiles'));
const Timings = lazy(() => import('./Pages/Admin/Configurations/timings'));
const SloganText = lazy(() => import('./Pages/Admin/Configurations/slogantext'));
const Address = lazy(() => import('./Pages/Admin/Configurations/address'));
const Staff = lazy(() => import('./Pages/Admin/staff'));
const AddStaff = lazy(() => import('./Pages/Admin/AddDetails/addstaff'));
const EditStaff = lazy(() => import('./Pages/Admin/EditPages/editstaff'));
const ConsultationQuery = lazy(() => import('./Pages/Admin/consultationquery'));
const ManageContent = lazy(() => import('./Pages/Admin/managecontent'));
const EditContent = lazy(() => import('./Pages/Admin/EditPages/editcontent'));
const Blogs = lazy(() => import('./Pages/Admin/blogs'));
const EditBlog = lazy(() => import('./Pages/Admin/EditPages/editblog'));
const AddBlog = lazy(() => import('./Pages/Admin/AddDetails/addblogs'));
const AdminServices = lazy(() => import('./Pages/Admin/adminservices'));
const AddService = lazy(() => import('./Pages/Admin/AddDetails/addservices'));
const EditService = lazy(() => import('./Pages/Admin/EditPages/editservices'));
const ManageDepartment = lazy(() => import('./Pages/Admin/managedepartment'));
const EditDepartment = lazy(() => import('./Pages/Admin/EditPages/editdepartment'));
const AddDepartment = lazy(() => import('./Pages/Admin/AddDetails/adddepartment'));
const ManageLocation = lazy(() => import('./Pages/Admin/managelocations'));
const AddLocation = lazy(() => import('./Pages/Admin/AddDetails/addlocation'));
const EditLocation = lazy(() => import('./Pages/Admin/EditPages/editlocation'));
const ManageContact = lazy(() => import('./Pages/Admin/managecontact'));
const SlotSettings = lazy(() => import('./Pages/Admin/EditPages/slotsettings'));
const ManageHolidays = lazy(() => import('./Pages/Admin/manageholidays'));
const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const isAdminPath = location.pathname.startsWith("/admin");
  const adminLogin = location.pathname.endsWith("/admin/login");
  const doctorLogin = location.pathname.endsWith("/doctor/login");
  const isDoctorPath = location.pathname.startsWith("/doctor");
  return (
    <>
      {adminLogin ? (
        <Suspense fallback={<LoaderH />}>
          <Routes>
            <Route path="/admin/login" element={<AdminLogin />} />
          </Routes>
        </Suspense>
      ) : null}

      {doctorLogin ? (
        <Suspense fallback={<LoaderH />}>
          <Routes>
            <Route path="/doctor/login" element={<DoctorLogin />} />
          </Routes>
        </Suspense>
      ) : null}

      {isDoctorPath && !adminLogin && !doctorLogin && !isAdminPath ? (
        <div className="flex flex-col md:flex-row w-full">
          <AdminHeader />
          <Suspense fallback={<LoaderH />}>
            <Routes>
              <Route path="/doctor" element={<ProtectedRoute />}>
                <Route path="/doctor" element={<DashBoard />} />
                <Route path="/doctor/manageslots" element={<ManageSlots />} />
                <Route path="/doctor/manageslots/settings" element={<SlotSettings />} />  
                <Route path="/doctor/appointments" element={<Appointments />} />
                <Route path="/doctor/manageholidays" element={<ManageHolidays />}/>
                <Route
                  path="/doctor/appointments/addappointments"
                  element={<AddAppointment />}
                />
                <Route
                  path="/doctor/appointments/editappointments/:id"
                  element={<EditAppointment />}
                />
                <Route path="/doctor/myprofile" element={<MyProfile />} />
                <Route
                  path="/doctor/myprofile/editprofile"
                  element={<EditProfile />}
                />
                <Route
                  path="/doctor/changepassword"
                  element={<ChangePassword />}
                />
                <Route path="/doctor/notification" element={<Notification />} />
              </Route>
            </Routes>
          </Suspense>
        </div>
      ) : null}

      {isAdminPath && !adminLogin && !doctorLogin && !isDoctorPath ? (
        // Admin routes
        <div className="flex flex-col md:flex-row w-full">
          <AdminHeader />
          <Suspense fallback={<LoaderH />}>
            <Routes>
              <Route path="/admin" element={<ProtectedRoute />}>
                <Route path="/admin" element={<DashBoard />} />
                <Route path="/admin/staff" element={<Staff />} />
                <Route path="/admin/appointments" element={<Appointments />} />
                <Route
                  path="/admin/consultationquery"
                  element={<ConsultationQuery />}
                />
                <Route
                  path="/admin/managecontent"
                  element={<ManageContent />}
                />
                <Route path="/admin/blogs" element={<Blogs />} />
                <Route path="/admin/services" element={<AdminServices />} />
                <Route
                  path="/admin/managecontact"
                  element={<ManageContact />}
                />
                <Route path="/admin/manageslots" element={<ManageSlots />} />
                <Route
                  path="/admin/managelocation"
                  element={<ManageLocation />}
                />
                <Route
                  path="/admin/managedepartment"
                  element={<ManageDepartment />}
                />
                {/* Profile Pages */}
                <Route path="/admin/myprofile" element={<MyProfile />} />
                <Route
                  path="/admin/changepassword"
                  element={<ChangePassword />}
                />
                <Route path="/admin/notification" element={<Notification />} />

                {/* Configuration Pages */}
                <Route path="/admin/logochange" element={<LogoChange />} />
                <Route
                  path="/admin/faviconchange"
                  element={<FaviconChange />}
                />
                <Route
                  path="/admin/socialmediaprofiles"
                  element={<SocialMediaProfiles />}
                />
                <Route path="/admin/timings" element={<Timings />} />
                <Route path="/admin/slogantext" element={<SloganText />} />
                <Route path="/admin/address" element={<Address />} />

                {/* Add Pages   */}

                <Route path="/admin/blogs/addblogs" element={<AddBlog />} />
                <Route path="/admin/staff/addstaff" element={<AddStaff />} />
                <Route
                  path="/admin/services/addservices"
                  element={<AddService />}
                />
                <Route
                  path="/admin/appointments/addappointments"
                  element={<AddAppointment />}
                />
                <Route
                  path="/admin/managelocation/addlocation"
                  element={<AddLocation />}
                />
                <Route
                  path="/admin/managedepartment/adddepartment"
                  element={<AddDepartment />}
                />

                {/* Edit Pages */}

                <Route
                  path="/admin/services/editservices/:id"
                  element={<EditService />}
                />
                <Route
                  path="/admin/staff/editstaff/:id"
                  element={<EditStaff />}
                />
                <Route
                  path="/admin/blogs/editblogs/:id"
                  element={<EditBlog />}
                />
                <Route
                  path="/admin/appointments/editappointments/:id"
                  element={<EditAppointment />}
                />
                <Route
                  path="/admin/managelocation/editlocation/:id"
                  element={<EditLocation />}
                />
                <Route
                  path="/admin/managedepartment/editdepartment/:id"
                  element={<EditDepartment />}
                />
                <Route
                  path="/admin/myprofile/editprofile"
                  element={<EditProfile />}
                />
                <Route
                  path="/admin/managecontent/editcontent/:slug"
                  element={<EditContent />}
                />
              </Route>
            </Routes>
          </Suspense>
        </div>
      ) : !adminLogin && !isAdminPath && !doctorLogin && !isDoctorPath ? (
        <>
          <Header />
          <Suspense fallback={<LoaderH />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/booking" element={<Booking />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/services" element={<Services />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/getdetails" element={<GetDetails />} />
              <Route path="/blogpage/:id" element={<BlogPage />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/termsofservice" element={<TermsofService />} />
              <Route path="*" element={<Error />} />
              <Route path='/loader' element={<LoaderH />}/>
            </Routes>
          </Suspense>
          <Footer />
        </>
      ) : null}
    </>
  );
};

export default App;
