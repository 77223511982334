import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiArrowDownSFill } from "react-icons/ri";
import { RiArrowDownSLine } from "react-icons/ri";
import { BiSolidDashboard } from "react-icons/bi";
import { FaPeopleGroup } from "react-icons/fa6";
import { MdMeetingRoom } from "react-icons/md";
import { BiSolidBookContent } from "react-icons/bi";
import { FaBloggerB } from "react-icons/fa";
import { GrServices } from "react-icons/gr";
import { BiSolidContact } from "react-icons/bi";
import { FcDataConfiguration } from "react-icons/fc";
import { AiFillInteraction } from "react-icons/ai";
import { PiSignOutBold } from "react-icons/pi";
import { MdNotificationsActive } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaUser } from "react-icons/fa6";
import { BiSolidShieldPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import BaseUrl from "../../Api/baseurl";
import { BiSolidNavigation } from "react-icons/bi";
import { BsPostcardFill } from "react-icons/bs";
import { eslintUseValue } from "@mui/x-data-grid/internals";
import { FaCalendarAlt } from "react-icons/fa";


const AdminHeader = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    new_logo: "",
  });
  const [activeTab, setActiveTab] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [superuser, setSuperuser] = useState(false);
  const [staff, setStaff] = useState(false);
  const active = window.location.pathname;

  const handleDropdownCLick = (tab) => {
    setDropdownOpen(false);
  };
  const handleTabClick = (tab) => {
    // setActiveTab(tab);
    // setDropdownOpen(false);
  };

  const handleLogout = (tab) => {
    Cookies.remove("is_superuser");
    Cookies.remove("is_staff");
    Cookies.remove("token");
    Cookies.remove("status");
    Cookies.remove("username");

    if (superuser) {
      navigate("/admin/login");
      window.location.href = "/admin/login";
    } else {
      navigate("/doctor/login");
      window.location.href = "/doctor/login";
    }
  };
  const fetchData = async () => {
    const apiUrl = `${BaseUrl}clinic/logochange/`;
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          // Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log(response.data);
      setData(response.data, "data");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const Suser = Cookies.get("is_superuser");
    if (Suser === "true") {
      setSuperuser(1);
    } else {
      setSuperuser(0);
    }

    setStaff(Cookies.get("is_staff"));
  }, []);
  

  return (
    <div className="w-full  md:w-[350px]">
      <div className="flex align-items-start w-full h-full">
        <div
          className="nav flex-column nav-pills  w-full h-full"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <div className="flex md:flex-col justify-between md:justify-normal items-center w-full h-full">
            {data && (
              <Link
                to={superuser ? "/admin" : "/doctor"}
                onClick={() => {
                  handleTabClick("/admin");
                  handleDropdownCLick();
                }}
              >
                <img
                  className="w-auto self-center py-3 px-4"
                  src={data.new_logo}
                  alt="Logo"
                />
              </Link>
            )}

            <div className="bg-[#113C54] !rounded-r-[15px] !rounded-l-[0px] hidden md:flex  md:flex-col py-12  pl-6 h-full w-full">
              {superuser ? (
                <>
                  <Link
                    to="/admin"
                    className={`flex items-center nav-link font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "/admin" || active === "/admin"
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("/admin");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "/admin" || active === "/admin"
                    }
                    on
                  >
                    <BiSolidDashboard className="mr-2" />
                    Dashboard
                  </Link>

                  <Link
                    to="#"
                    className={`nav-link font-poppins text-[20px] text-[#ffffff] flex items-center font-normal leading-[33px] text-left `}
                    onClick={() => {
                      setDropdownOpen(!dropdownOpen);
                    }} // Toggle dropdown visibility
                    role="tab"
                  >
                    <FcDataConfiguration className="mr-2" />
                    Configurations{" "}
                    <RiArrowDownSFill className="ml-2 text-[25px]" />
                  </Link>
                  {dropdownOpen && (
                    <div className="mt-1 w-48 w-full bg-white border border-gray-300 !rounded-l-[20px] shadow-lg">
                      <Link
                        to="admin/logochange"
                        className={`block px-4 py-2 text-[#113C54] hover:text-[#ffffff] hover:bg-[#113C54] !rounded-l-[20px]  ${
                          activeTab === "logochange" ||
                          active === "/admin/logochange"
                            ? "text-[#ffffff] bg-[#113C54]"
                            : "text-[#113C54]"
                        }`}
                        onClick={() => handleTabClick("logochange")}
                        aria-selected={
                          activeTab === "logochange" ||
                          active === "/admin/logochange"
                        }
                      >
                        Logo Change
                      </Link>
                      <hr className="text-black-800 border-[2px] mx-4" />
                      <Link
                        to="admin/faviconchange"
                        className={`block px-4 py-2 text-[#113C54] hover:text-[#ffffff] hover:bg-[#113C54] !rounded-l-[20px]  ${
                          activeTab === "faviconchange" ||
                          active === "/admin/faviconchange"
                            ? "text-[#ffffff] bg-[#113C54]"
                            : "text-[#113C54]"
                        }`}
                        onClick={() => handleTabClick("faviconchange")}
                        aria-selected={
                          activeTab === "faviconchange" ||
                          active === "/admin/faviconchange"
                        }
                      >
                        Favicon Change
                      </Link>
                      <hr className="text-black-800 border-[2px] mx-4" />
                      <Link
                        to="admin/socialmediaprofiles"
                        className={`block px-4 py-2 text-[#113C54] hover:text-[#ffffff] hover:bg-[#113C54] !rounded-l-[20px]  ${
                          activeTab === "socialmediaprofiles" ||
                          active === "/admin/socialmediaprofiles"
                            ? "text-[#ffffff] bg-[#113C54]"
                            : "text-[#113C54]"
                        }`}
                        onClick={() => handleTabClick("socialmediaprofiles")}
                        aria-selected={
                          activeTab === "socialmediaprofiles" ||
                          active === "/admin/socialmediaprofiles"
                        }
                      >
                        Social Media Profiles
                      </Link>
                      <hr className="text-black-800 border-[2px] mx-4" />
                      <Link
                        to="admin/timings"
                        className={`block px-4 py-2 text-[#113C54] hover:text-[#ffffff] hover:bg-[#113C54] !rounded-l-[20px]  ${
                          activeTab === "timings" || active === "/admin/timings"
                            ? "text-[#ffffff] bg-[#113C54]"
                            : "text-[#113C54]"
                        }`}
                        onClick={() => handleTabClick("timings")}
                        aria-selected={
                          activeTab === "timings" || active === "/admin/timings"
                        }
                      >
                        Update Timings
                      </Link>
                      <hr className="text-black-800 border-[2px] mx-4" />
                      <Link
                        to="admin/slogantext"
                        className={`block px-4 py-2 text-[#113C54] hover:text-[#ffffff] hover:bg-[#113C54] !rounded-l-[20px]  ${
                          activeTab === "slogantext" ||
                          active === "/admin/slogantext"
                            ? "text-[#ffffff] bg-[#113C54]"
                            : "text-[#113C54]"
                        }`}
                        onClick={() => handleTabClick("slogantext")}
                        aria-selected={
                          activeTab === "slogantext" ||
                          active === "/admin/slogantext"
                        }
                      >
                        Update Slogan Text
                      </Link>
                      <hr className="text-black-800 border-[2px] mx-4" />
                      <Link
                        to="admin/address"
                        className={`block px-4 py-2 text-[#113C54] hover:text-[#ffffff] hover:bg-[#113C54] !rounded-l-[20px]  ${
                          activeTab === "address" || active === "/admin/address"
                            ? "text-[#ffffff] bg-[#113C54]"
                            : "text-[#113C54]"
                        }`}
                        onClick={() => handleTabClick("address")}
                        aria-selected={
                          activeTab === "address" || active === "/admin/address"
                        }
                      >
                        Update Address
                      </Link>
                    </div>
                  )}
                  <Link
                    to="/admin/staff"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "staff" || active.includes("/admin/staff")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("staff");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "staff" || active.includes("/admin/staff")
                    }
                  >
                    <FaPeopleGroup className="mr-2" />
                    Manage Staff
                  </Link>
                  {/* <Link
                    to="/admin/manageslots"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "slots" ||
                      active.includes("/admin/manageslots")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("slots");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "slots" ||
                      active.includes("/admin/manageslots")
                    }
                  >
                    <BiSolidShieldPlus className="mr-2" />
                    Manage Slots
                  </Link> */}
                  <Link
                    to="/admin/appointments"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "appointments" ||
                      active.includes("/admin/appointments")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("appointments");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "appointments" ||
                      active.includes("/admin/appointments")
                    }
                  >
                    <MdMeetingRoom className="mr-2" /> Appointments
                  </Link>
                  <Link
                    to="/admin/consultationquery"
                    className={`nav-link flex items-center font-poppins text-[18px] font-normal leading-[33px] text-left ${
                      activeTab === "consultation_queries" ||
                      active.includes("/admin/consultationquery")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("consultation_queries");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "consultation_queries" ||
                      active.includes("/admin/consultationquery")
                    }
                  >
                    <AiFillInteraction className="mr-2" />
                    Consultation Queries
                  </Link>

                  <Link
                    to="/admin/managecontent"
                    className={`nav-link flex items-center font-poppins flex items-center text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "manage_content" ||
                      active.includes("/admin/managecontent")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("manage_content");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "manage_content" ||
                      active.includes("/admin/managecontent")
                    }
                  >
                    <BiSolidBookContent className="mr-2" />
                    Manage Content
                  </Link>
                  <Link
                    to="/admin/blogs"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "manage_blogs" ||
                      active.includes("/admin/blogs")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("manage_blogs");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "manage_blogs" ||
                      active.includes("/admin/blogs")
                    }
                  >
                    <FaBloggerB className="mr-2" />
                    Manage Blogs
                  </Link>
                  <Link
                    to="/admin/services"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "manage_services" ||
                      active.includes("/admin/services")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("manage_services");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "manage_services" ||
                      active.includes("/admin/services")
                    }
                  >
                    <GrServices className="mr-2" />
                    Manage Services
                  </Link>
                  <Link
                    to="/admin/managedepartment"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "manage_department" ||
                      active.includes("/admin/managedepartment")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("manage_department");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "manage_department" ||
                      active.includes("/admin/managedepartment")
                    }
                  >
                    <BsPostcardFill className="mr-2" />
                    Manage Department
                  </Link>
                  <Link
                    to="/admin/managecontact"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "manage_feedback" ||
                      active.includes("/admin/managecontact")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("manage_contact");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "manage_contact" ||
                      active.includes("/admin/managecontact")
                    }
                  >
                    <BiSolidContact className="mr-2" />
                    Manage Contact
                  </Link>
                  <Link
                    to="/admin/managelocation"
                    className={`nav-link font-poppins flex items-center text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "managelocation" ||
                      active.includes("/admin/managelocation")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => handleTabClick("managelocation")}
                    role="tab"
                    aria-selected={
                      activeTab === "managelocation" ||
                      active.includes("/admin/managelocation")
                    }
                  >
                    <BiSolidNavigation className="mr-2" />
                    Manage Locations
                  </Link>
                  <hr className="text-[#ffffff] border-[3px] my-4" />
                  <span className="text-[#ffffff] font-poppins text-[28px] font-[500] leading-[42px] text-left ">
                    Profile
                  </span>
                  <Link
                    to="/admin/myprofile"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left mt-2 ${
                      activeTab === "myprofile" ||
                      active.includes("/admin/myprofile")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("myprofile");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "myprofile" ||
                      active.includes("/admin/myprofile")
                    }
                  >
                    <FaUser className="mr-2" />
                    My Profile
                  </Link>
                  <Link
                    to="/admin/changepassword"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "change-password" ||
                      active === "/admin/changepassword"
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("change-password");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "change-password" ||
                      active.includes("/admin/changepassword")
                    }
                  >
                    <RiLockPasswordFill className="mr-2" />
                    Change Password
                  </Link>

                  <Link
                    to="/admin/notification"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "notification" ||
                      active === "/admin/notification"
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("notification");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "notification" ||
                      active === "/admin/notification"
                    }
                  >
                    <MdNotificationsActive className="mr-2" />
                    Notifications
                  </Link>
                  <Link
                    to="#"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "about"
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("logout");
                      handleDropdownCLick();
                      handleLogout();
                    }}
                    role="tab"
                    aria-selected={activeTab === "logout"}
                  >
                    <PiSignOutBold className="mr-2" />
                    Log Out
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="/doctor"
                    className={`flex items-center nav-link font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "/doctor" || active === "/doctor"
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("/doctor");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "/doctor" || active === "/doctor"
                    }
                    on
                  >
                    <BiSolidDashboard className="mr-2" />
                    Dashboard
                  </Link>

                  <Link
                    to="/doctor/manageslots"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "slots" ||
                      active.includes("/doctor/manageslots")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("slots");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "slots" ||
                      active.includes("/doctor/manageslots")
                    }
                  >
                    <BiSolidShieldPlus className="mr-2" />
                    Manage Slots
                  </Link>
                  <Link
                    to="/doctor/appointments"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "appointments" ||
                      active.includes("/doctor/appointments")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("appointments");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "appointments" ||
                      active.includes("/doctor/appointments")
                    }
                  >
                    <MdMeetingRoom className="mr-2" /> Appointments
                  </Link>
                  <Link
                    to="/doctor/manageholidays"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "manageholidays" ||
                      active.includes("/doctor/manageholidays")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("manageholidays");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "manageholidays" ||
                      active.includes("/doctor/manageholidays")
                    }
                  >
                    <FaCalendarAlt className="mr-2" /> Manage Holidays
                  </Link>
                  <hr className="text-[#ffffff] border-[3px] my-4" />
                  <span className="text-[#ffffff] font-poppins text-[28px] font-[500] leading-[42px] text-left ">
                    Profile
                  </span>
                  <Link
                    to="/doctor/myprofile"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left mt-2 ${
                      activeTab === "myprofile" ||
                      active.includes("/doctor/myprofile")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("myprofile");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "myprofile" ||
                      active.includes("/doctor/myprofile")
                    }
                  >
                    <FaUser className="mr-2" />
                    My Profile
                  </Link>
                  <Link
                    to="/doctor/changepassword"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "change-password" ||
                      active === "/doctor/changepassword"
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("change-password");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "change-password" ||
                      active.includes("/doctor/changepassword")
                    }
                  >
                    <RiLockPasswordFill className="mr-2" />
                    Change Password
                  </Link>
                  <Link
                    to="/doctor/notification"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "notification" ||
                      active === "/doctor/notification"
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("notification");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "notification" ||
                      active === "/doctor/notification"
                    }
                  >
                    <MdNotificationsActive className="mr-2" />
                    Notifications
                  </Link>
                  <Link
                    to="#"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "about"
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("logout");
                      handleDropdownCLick();
                      handleLogout();
                    }}
                    role="tab"
                    aria-selected={activeTab === "logout"}
                  >
                    <PiSignOutBold className="mr-2" />
                    Log Out
                  </Link>
                </>
              )}
            </div>
            <div className="md:hidden flex-shrink-0">
              <button
                onClick={() => setShowMenu(!showMenu)}
                className="text-xl text-gray-700 hover:text-gray-900 focus:outline-none mr-4"
              >
                {showMenu ? <GrClose /> : <GiHamburgerMenu />}
              </button>
            </div>
          </div>
          {showMenu && (
            <div className="md:hidden bg-[#113C54] shadow-md py-2 px-4">
              <nav className="flex flex-col space-y-2">
              {superuser ? (
                <>
                  <Link
                    to="/admin"
                    className={`flex items-center nav-link font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "/admin" || active === "/admin"
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("/admin");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "/admin" || active === "/admin"
                    }
                    on
                  >
                    <BiSolidDashboard className="mr-2" />
                    Dashboard
                  </Link>

                  <Link
                    to="#"
                    className={`nav-link font-poppins text-[20px] text-[#ffffff] flex items-center font-normal leading-[33px] text-left `}
                    onClick={() => {
                      setDropdownOpen(!dropdownOpen);
                    }} // Toggle dropdown visibility
                    role="tab"
                  >
                    <FcDataConfiguration className="mr-2" />
                    Configurations{" "}
                    <RiArrowDownSFill className="ml-2 text-[25px]" />
                  </Link>
                  {dropdownOpen && (
                    <div className="mt-1 w-48 w-full bg-white border border-gray-300 !rounded-l-[20px] shadow-lg">
                      <Link
                        to="admin/logochange"
                        className={`block px-4 py-2 text-[#113C54] hover:text-[#ffffff] hover:bg-[#113C54] !rounded-l-[20px]  ${
                          activeTab === "logochange" ||
                          active === "/admin/logochange"
                            ? "text-[#ffffff] bg-[#113C54]"
                            : "text-[#113C54]"
                        }`}
                        onClick={() => handleTabClick("logochange")}
                        aria-selected={
                          activeTab === "logochange" ||
                          active === "/admin/logochange"
                        }
                      >
                        Logo Change
                      </Link>
                      <hr className="text-black-800 border-[2px] mx-4" />
                      <Link
                        to="admin/faviconchange"
                        className={`block px-4 py-2 text-[#113C54] hover:text-[#ffffff] hover:bg-[#113C54] !rounded-l-[20px]  ${
                          activeTab === "faviconchange" ||
                          active === "/admin/faviconchange"
                            ? "text-[#ffffff] bg-[#113C54]"
                            : "text-[#113C54]"
                        }`}
                        onClick={() => handleTabClick("faviconchange")}
                        aria-selected={
                          activeTab === "faviconchange" ||
                          active === "/admin/faviconchange"
                        }
                      >
                        Favicon Change
                      </Link>
                      <hr className="text-black-800 border-[2px] mx-4" />
                      <Link
                        to="admin/socialmediaprofiles"
                        className={`block px-4 py-2 text-[#113C54] hover:text-[#ffffff] hover:bg-[#113C54] !rounded-l-[20px]  ${
                          activeTab === "socialmediaprofiles" ||
                          active === "/admin/socialmediaprofiles"
                            ? "text-[#ffffff] bg-[#113C54]"
                            : "text-[#113C54]"
                        }`}
                        onClick={() => handleTabClick("socialmediaprofiles")}
                        aria-selected={
                          activeTab === "socialmediaprofiles" ||
                          active === "/admin/socialmediaprofiles"
                        }
                      >
                        Social Media Profiles
                      </Link>
                      <hr className="text-black-800 border-[2px] mx-4" />
                      <Link
                        to="admin/timings"
                        className={`block px-4 py-2 text-[#113C54] hover:text-[#ffffff] hover:bg-[#113C54] !rounded-l-[20px]  ${
                          activeTab === "timings" || active === "/admin/timings"
                            ? "text-[#ffffff] bg-[#113C54]"
                            : "text-[#113C54]"
                        }`}
                        onClick={() => handleTabClick("timings")}
                        aria-selected={
                          activeTab === "timings" || active === "/admin/timings"
                        }
                      >
                        Update Timings
                      </Link>
                      <hr className="text-black-800 border-[2px] mx-4" />
                      <Link
                        to="admin/slogantext"
                        className={`block px-4 py-2 text-[#113C54] hover:text-[#ffffff] hover:bg-[#113C54] !rounded-l-[20px]  ${
                          activeTab === "slogantext" ||
                          active === "/admin/slogantext"
                            ? "text-[#ffffff] bg-[#113C54]"
                            : "text-[#113C54]"
                        }`}
                        onClick={() => handleTabClick("slogantext")}
                        aria-selected={
                          activeTab === "slogantext" ||
                          active === "/admin/slogantext"
                        }
                      >
                        Update Slogan Text
                      </Link>
                      <hr className="text-black-800 border-[2px] mx-4" />
                      <Link
                        to="admin/address"
                        className={`block px-4 py-2 text-[#113C54] hover:text-[#ffffff] hover:bg-[#113C54] !rounded-l-[20px]  ${
                          activeTab === "address" || active === "/admin/address"
                            ? "text-[#ffffff] bg-[#113C54]"
                            : "text-[#113C54]"
                        }`}
                        onClick={() => handleTabClick("address")}
                        aria-selected={
                          activeTab === "address" || active === "/admin/address"
                        }
                      >
                        Update Address
                      </Link>
                    </div>
                  )}
                  <Link
                    to="/admin/staff"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "staff" || active.includes("/admin/staff")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("staff");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "staff" || active.includes("/admin/staff")
                    }
                  >
                    <FaPeopleGroup className="mr-2" />
                    Manage Staff
                  </Link>
                  {/* <Link
                    to="/admin/manageslots"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "slots" ||
                      active.includes("/admin/manageslots")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("slots");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "slots" ||
                      active.includes("/admin/manageslots")
                    }
                  >
                    <BiSolidShieldPlus className="mr-2" />
                    Manage Slots
                  </Link> */}
                  <Link
                    to="/admin/appointments"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "appointments" ||
                      active.includes("/admin/appointments")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("appointments");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "appointments" ||
                      active.includes("/admin/appointments")
                    }
                  >
                    <MdMeetingRoom className="mr-2" /> Appointments
                  </Link>
                  <Link
                    to="/admin/consultationquery"
                    className={`nav-link flex items-center font-poppins text-[18px] font-normal leading-[33px] text-left ${
                      activeTab === "consultation_queries" ||
                      active.includes("/admin/consultationquery")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("consultation_queries");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "consultation_queries" ||
                      active.includes("/admin/consultationquery")
                    }
                  >
                    <AiFillInteraction className="mr-2" />
                    Consultation Queries
                  </Link>

                  <Link
                    to="/admin/managecontent"
                    className={`nav-link flex items-center font-poppins flex items-center text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "manage_content" ||
                      active.includes("/admin/managecontent")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("manage_content");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "manage_content" ||
                      active.includes("/admin/managecontent")
                    }
                  >
                    <BiSolidBookContent className="mr-2" />
                    Manage Content
                  </Link>
                  <Link
                    to="/admin/blogs"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "manage_blogs" ||
                      active.includes("/admin/blogs")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("manage_blogs");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "manage_blogs" ||
                      active.includes("/admin/blogs")
                    }
                  >
                    <FaBloggerB className="mr-2" />
                    Manage Blogs
                  </Link>
                  <Link
                    to="/admin/services"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "manage_services" ||
                      active.includes("/admin/services")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("manage_services");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "manage_services" ||
                      active.includes("/admin/services")
                    }
                  >
                    <GrServices className="mr-2" />
                    Manage Services
                  </Link>
                  <Link
                    to="/admin/managedepartment"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "manage_department" ||
                      active.includes("/admin/managedepartment")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("manage_department");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "manage_department" ||
                      active.includes("/admin/managedepartment")
                    }
                  >
                    <BsPostcardFill className="mr-2" />
                    Manage Department
                  </Link>
                  <Link
                    to="/admin/managecontact"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "manage_feedback" ||
                      active.includes("/admin/managecontact")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("manage_contact");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "manage_contact" ||
                      active.includes("/admin/managecontact")
                    }
                  >
                    <BiSolidContact className="mr-2" />
                    Manage Contact
                  </Link>
                  <Link
                    to="/admin/managelocation"
                    className={`nav-link font-poppins flex items-center text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "managelocation" ||
                      active.includes("/admin/managelocation")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => handleTabClick("managelocation")}
                    role="tab"
                    aria-selected={
                      activeTab === "managelocation" ||
                      active.includes("/admin/managelocation")
                    }
                  >
                    <BiSolidNavigation className="mr-2" />
                    Manage Locations
                  </Link>
                  <hr className="text-[#ffffff] border-[3px] my-4" />
                  <span className="text-[#ffffff] font-poppins text-[28px] font-[500] leading-[42px] text-left ">
                    Profile
                  </span>
                  <Link
                    to="/admin/myprofile"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left mt-2 ${
                      activeTab === "myprofile" ||
                      active.includes("/admin/myprofile")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("myprofile");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "myprofile" ||
                      active.includes("/admin/myprofile")
                    }
                  >
                    <FaUser className="mr-2" />
                    My Profile
                  </Link>
                  <Link
                    to="/admin/changepassword"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "change-password" ||
                      active === "/admin/changepassword"
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("change-password");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "change-password" ||
                      active.includes("/admin/changepassword")
                    }
                  >
                    <RiLockPasswordFill className="mr-2" />
                    Change Password
                  </Link>

                  <Link
                    to="/admin/notification"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "notification" ||
                      active === "/admin/notification"
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("notification");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "notification" ||
                      active === "/admin/notification"
                    }
                  >
                    <MdNotificationsActive className="mr-2" />
                    Notifications
                  </Link>
                  <Link
                    to="#"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "about"
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("logout");
                      handleDropdownCLick();
                      handleLogout();
                    }}
                    role="tab"
                    aria-selected={activeTab === "logout"}
                  >
                    <PiSignOutBold className="mr-2" />
                    Log Out
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="/doctor"
                    className={`flex items-center nav-link font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "/doctor" || active === "/doctor"
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("/doctor");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "/doctor" || active === "/doctor"
                    }
                    on
                  >
                    <BiSolidDashboard className="mr-2" />
                    Dashboard
                  </Link>

                  <Link
                    to="/doctor/manageslots"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "slots" ||
                      active.includes("/doctor/manageslots")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("slots");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "slots" ||
                      active.includes("/doctor/manageslots")
                    }
                  >
                    <BiSolidShieldPlus className="mr-2" />
                    Manage Slots
                  </Link>
                  <Link
                    to="/doctor/appointments"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "appointments" ||
                      active.includes("/doctor/appointments")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("appointments");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "appointments" ||
                      active.includes("/doctor/appointments")
                    }
                  >
                    <MdMeetingRoom className="mr-2" /> Appointments
                  </Link>
                  <Link
                    to="/doctor/manageholidays"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "manageholidays" ||
                      active.includes("/doctor/manageholidays")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("manageholidays");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "manageholidays" ||
                      active.includes("/doctor/manageholidays")
                    }
                  >
                    <FaCalendarAlt className="mr-2" /> Manage Holidays
                  </Link>
                  <hr className="text-[#ffffff] border-[3px] my-4" />
                  <span className="text-[#ffffff] font-poppins text-[28px] font-[500] leading-[42px] text-left ">
                    Profile
                  </span>
                  <Link
                    to="/doctor/myprofile"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left mt-2 ${
                      activeTab === "myprofile" ||
                      active.includes("/doctor/myprofile")
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("myprofile");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "myprofile" ||
                      active.includes("/doctor/myprofile")
                    }
                  >
                    <FaUser className="mr-2" />
                    My Profile
                  </Link>
                  <Link
                    to="/doctor/changepassword"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "change-password" ||
                      active === "/doctor/changepassword"
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("change-password");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "change-password" ||
                      active.includes("/doctor/changepassword")
                    }
                  >
                    <RiLockPasswordFill className="mr-2" />
                    Change Password
                  </Link>
                  <Link
                    to="/doctor/notification"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "notification" ||
                      active === "/doctor/notification"
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("notification");
                      handleDropdownCLick();
                    }}
                    role="tab"
                    aria-selected={
                      activeTab === "notification" ||
                      active === "/doctor/notification"
                    }
                  >
                    <MdNotificationsActive className="mr-2" />
                    Notifications
                  </Link>
                  <Link
                    to="#"
                    className={`nav-link flex items-center font-poppins text-[20px] font-normal leading-[33px] text-left ${
                      activeTab === "about"
                        ? "text-[#113C54] bg-[#ffffff] !rounded-l-[30px] !rounded-r-[0px]"
                        : "text-[#ffffff] rounded-[0px]"
                    }`}
                    onClick={() => {
                      handleTabClick("logout");
                      handleDropdownCLick();
                      handleLogout();
                    }}
                    role="tab"
                    aria-selected={activeTab === "logout"}
                  >
                    <PiSignOutBold className="mr-2" />
                    Log Out
                  </Link>
                </>
              )}
              </nav>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
